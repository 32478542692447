import React from "react";
import { RegisterWithUs } from "../components/RegisterWithUs";
import { GlobalStyle } from "../style/globalStyles";
export default function registerWithUs() {
  return (
    <>
      <GlobalStyle />
      <main>
        {/* "Hey Zyppys register!" */}
        <RegisterWithUs />
      </main>
    </>
  );
}
